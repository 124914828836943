import "./App.less";
import { Layout, Menu, Dropdown, Modal, Skeleton, Breadcrumb } from "antd";
import { DownOutlined, LoadingOutlined, HomeFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Link, Redirect, Route, Switch, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import SettingsMain from "./containers/Settings/SettingsMain";
import isotype from "./images/isotype.png";
import Sidebar from "./Sidebar";
import OrdersMain from "./containers/Orders/OrdersMain";
import MenuMain from "./containers/Menu/MenuMain";
import OrdersReportMain from "./containers/OrderReport/OrderReportMain";
import RewardsMain from "./containers/Rewards/RewardsMain";
import { useDispatch } from "react-redux";
import { logout } from "./store/actions/auth";
import { useFirestoreConnect } from 'react-redux-firebase';
import ErrorBoundary from "./ErrorBoundary";


const { Header, Content } = Layout;

function Main() {
  const currentUser = useSelector((state) => state.currentUser);
  const [collapsed, setCollapsed] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  useFirestoreConnect([
    { collection: 'venues', doc: currentUser.id },
    { collection: 'menus', where: ['venue', '==', currentUser.id] },
    { collection: 'orders', where: ['venue', '==', currentUser.id] },
    { collection: 'rewards', where: ['venue', '==', currentUser.id] },
    { collection: 'items', where: ['venue', '==', currentUser.id] },
  ]);
  const venue = useSelector(
    ({ firestore: { data } }) => data.venues && data.venues[currentUser.id]
  );

  useEffect(() => {
    // dispatch(getVenueInfo(currentUser.id))
    return () => {};
  }, [currentUser, dispatch]);

  const handleLogout = () => {
    Modal.confirm({
      title: "Do you want to log out?",
      onOk: () => dispatch(logout()),
      className: "delete-modal-confirm",
      icon: null,
      cancelButtonProps: { type: "text" },
      okText: "Yes, log out",
      cancelText: "No",
    });
  };

  const menu = (
    <Menu>
      <Menu.Item key="0" onClick={() => history.push("/settings/info")}>
        Settings
      </Menu.Item>
      <Menu.Item key="1">
        Talk with support
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3" onClick={handleLogout}>
        Log out
      </Menu.Item>
    </Menu>
  );
  return (
    <Layout id="app">
      <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout
        style={{ marginLeft: collapsed ? 60 : 200, transition: "all 0.35s" }}
      >
        <Header
          style={{
            padding: "0px 24px",
            background: "#081B33",
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
            boxShadow: "0 3px 8px 0 rgb(0 0 0 / 10%)",
          }}
        >
          <div style={{ padding: '0px 24px' }}>
            <Breadcrumb>
              <Breadcrumb.Item key="home">
                <Link to="/"><HomeFilled /></Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/" style={{ fontSize: 18 }}>
                  {venue ? venue.name : <LoadingOutlined />}
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Dropdown overlay={menu} trigger={["click"]}>
            <div className="header-menu">
              <div className="isotype-wrapper">
                <img src={isotype} alt="" />
              </div>
              <DownOutlined style={{ marginLeft: 6, color: 'white' }} />
            </div>
          </Dropdown>
        </Header>
        <Content
          style={{
            padding: "60px 42px",
            background: "#152642",
          }}
        >
          <ErrorBoundary>
            <Switch>
              <Route path="/tab-timeline">
                {venue ? <OrdersMain venue={venue} /> : <Skeleton active />}
              </Route>
              <Route path="/tab-report">
                {venue ? <OrdersReportMain /> : <Skeleton active />}
              </Route>
              <Route path="/menu">
                {venue ? <MenuMain /> : <Skeleton active />}
              </Route>
              <Route path="/rewards">
                {venue ? <RewardsMain /> : <Skeleton active />}
              </Route>
              <Route path="/settings">
                {venue ? <SettingsMain venue={venue}/> : <Skeleton active />}
              </Route>
              <Redirect to="/tab-timeline" />
            </Switch>
          </ErrorBoundary>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Main;
