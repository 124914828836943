import * as React from "react"

function VaccineIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        data-name="Mask Group 5807"
        transform="translate(-721 -183)"
        clipPath="url(#prefix__clip-path)"
      >
        <path
          data-name="Path 5955"
          d="M731.774 198.3l-.157-.053v-1.138h-5.156v1.114l-.068.02a3.252 3.252 0 00-2.326 3.1v4.957a.7.7 0 00.7.7h8.49a.7.7 0 00.7-.7v-4.939a3.234 3.234 0 00-2.19-3.061z"
        />
        <path
          data-name="Path 5956"
          d="M725.761 195.7h6.563a.7.7 0 00.7-.7v-2.341a.7.7 0 00-.7-.7h-6.563a.7.7 0 00-.7.7V195a.7.7 0 00.7.7z"
        />
        <path
          data-name="Path 5957"
          d="M740.526 187.969a.7.7 0 00-.7-.7h-.705V183.7a.703.703 0 00-1.406 0v3.566h-.7a.7.7 0 00-.7.7v.7h4.219z"
        />
        <path
          data-name="Path 5958"
          d="M741.229 202.734v-2.812H739.1a.703.703 0 010-1.406h2.131v-1.407H739.1a.703.703 0 010-1.406h2.131V194.3H739.1a.703.703 0 010-1.406h2.131v-2.113a.7.7 0 00-.7-.7h-4.226a.7.7 0 00-.7.7v11.953a.703.703 0 000 1.406h2.107v1.453h-1.4a.703.703 0 000 1.406h4.214a.703.703 0 000-1.406h-1.408v-1.452h2.112a.703.703 0 000-1.406z"
        />
      </g>
    </svg>
  )
}

export default VaccineIcon
