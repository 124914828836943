import * as React from "react"

function OutdoorIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        data-name="Mask Group 5759"
        transform="translate(-120 -74)"
        clipPath="url(#prefix__clip-path)"
      >
        <path
          data-name="Path 5630"
          d="M130.493 93.576h-4.755l.924-1.6h1.2a.706.706 0 000-1.412h-1.6a.709.709 0 00-.491.2 25.425 25.425 0 00-1.655 2.812h-1.4l-1.347-3.676a.706.706 0 10-1.327.482c1.646 4.525 1.514 4.191 1.587 4.3a.708.708 0 00.452.295 9.652 9.652 0 001.211.012l-1.127 1.951a.706.706 0 101.222.706l1.535-2.659h2.9l1.535 2.659a.706.706 0 001.222-.706l-1.122-1.953h1.036a.706.706 0 000-1.412z"
        />
        <path
          data-name="Path 5631"
          d="M132.761 85.974l-2.949-2.028a.706.706 0 01.8-1.163l2.157 1.483.03-5.8a.706.706 0 011.412.008l-.032 6.2 2.591-2.59a.707.707 0 011 1l-3.6 3.6-.015 2.988a4.467 4.467 0 002.444-1.2 4.847 4.847 0 101.751-9.526 4.847 4.847 0 10-9.691 0 4.847 4.847 0 101.753 9.528 4.451 4.451 0 002.33 1.176c.013-2.567 0-.62.019-3.676z"
        />
        <path
          data-name="Path 5632"
          d="M143.074 86.853a6.258 6.258 0 01-6.059 3.115 5.872 5.872 0 01-4.28 1.111l-.032 6.21a.706.706 0 101.412.008l.029-5.637a3.142 3.142 0 002.34.527 2.92 2.92 0 001.209.625v4.48a.706.706 0 001.412 0v-4.48a2.925 2.925 0 001.209-.626 3.155 3.155 0 002.76-5.335z"
        />
      </g>
    </svg>
  )
}

export default OutdoorIcon
