import * as React from "react"

function DistancingIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        data-name="Mask Group 5758"
        transform="translate(-147 -74)"
        clipPath="url(#prefix__clip-path)"
      >
        <path
          data-name="Path 5625"
          d="M163.391 78.032l-1.013.976.743.771h-8.242l.743-.771-1.013-.976-2.36 2.45 2.361 2.45 1.013-.976-.743-.771h8.241l-.743.771 1.013.976 2.361-2.45z"
        />
        <path
          data-name="Path 5626"
          d="M153.535 88.49a3.662 3.662 0 01-4.983 0A3.842 3.842 0 00147 91.575v2.394h8.087v-2.394a3.842 3.842 0 00-1.552-3.085z"
        />
        <path
          data-name="Path 5627"
          d="M169.448 88.49a3.662 3.662 0 01-4.983 0 3.842 3.842 0 00-1.552 3.085v2.394H171v-2.394a3.842 3.842 0 00-1.552-3.085z"
        />
        <path
          data-name="Path 5628"
          d="M153.308 85.8v-.391a2.265 2.265 0 00-4.529 0v.391a2.265 2.265 0 104.529 0z"
        />
        <path
          data-name="Path 5629"
          d="M169.221 85.8v-.391a2.265 2.265 0 00-4.529 0v.391a2.265 2.265 0 104.529 0z"
        />
      </g>
    </svg>
  )
}

export default DistancingIcon
