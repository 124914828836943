import * as React from "react"

function FaceMaskIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={123}
      height={123}
      viewBox="0 0 123 123"
      {...props}
    >
      <g
        data-name="Mask Group 5760"
        transform="translate(-.458 -.458)"
        clipPath="url(#prefix__clip-path)"
      >
        <path
          id="tintPath"
          data-name="Path 5633"
          d="M10.915 50.004v.029a48.848 48.848 0 0017.22 37.228 9.332 9.332 0 012.02 2.408l27.1-22.789 40.668-34.2a24.757 24.757 0 007.252-17.5V5.821c-9.422 4.53-12.797 3.759-23.057 1.784-6.056-1.027-18.867-3.954-24.868-3.944a46.343 46.343 0 00-46.335 46.343z"
          fill="#797979"
        />
        <path
          id="facePath"
          data-name="Path 5635"
          d="M25.417 119.674h58v-14.5l20.557-43.075-6.055-10.082V32.673h-24.46a11.79 11.79 0 00-11.791 11.789v9.961l-4.417 1.27-10.877 3.128-6.456 17.353-9.762 13.493a9.359 9.359 0 011.01 7.008z"
          fill="#d3d3d3"
        />
        <path
          id="tintPath"
          data-name="Path 5637"
          d="M39.917 65.833v10.34l17.334 9.5 17.302 9.48v-8.252l-17.3-9.477-10.1-5.535v-6.056c.164-5.417 7.107-8.136 10.1-5.142l17.3 24.32V72.533l-12.886-18.11a13.924 13.924 0 00-4.414-2.042c-8.112-2.182-17.406 3.966-17.336 13.452z"
          fill="#797979"
        />
        <path
          id="tintPath"
          data-name="Path 5640"
          d="M71.995 68.925v24.826a11.424 11.424 0 0011.422 11.425h16.1a7.239 7.239 0 007.023-5.485l5.88-23.52-8.448-14.072-6.054 6.825z"
          fill="#797979"
        />
      </g>
    </svg>
  )
}

export default FaceMaskIcon
