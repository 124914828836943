import * as React from "react"

function CleaningIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        data-name="Mask Group 5757"
        transform="translate(-174 -74)"
        clipPath="url(#prefix__clip-path)"
      >
        <g data-name="clean (1)">
          <path
            data-name="Path 5622"
            d="M197.3 90.578a5.32 5.32 0 01-3.773-1.55 5.243 5.243 0 01-1.56-3.747.703.703 0 00-1.406 0A5.243 5.243 0 01189 89.028a5.32 5.32 0 01-3.773 1.55.703.703 0 000 1.406 5.329 5.329 0 015.33 5.316.703.703 0 001.406 0 5.329 5.329 0 015.337-5.315.703.703 0 000-1.406zm0 0"
          />
          <path
            data-name="Path 5623"
            d="M183.62 85.081a3.746 3.746 0 01-3.755-3.728.703.703 0 00-1.406 0 3.746 3.746 0 01-3.759 3.728.703.703 0 100 1.406 3.752 3.752 0 013.755 3.74.703.703 0 101.406 0 3.752 3.752 0 013.755-3.74.703.703 0 000-1.406zm0 0"
          />
          <path
            data-name="Path 5624"
            d="M184.764 78.859a2.755 2.755 0 012.762 2.741.703.703 0 001.406 0 2.759 2.759 0 012.762-2.75.703.703 0 100-1.406 2.755 2.755 0 01-2.762-2.744.703.703 0 10-1.406 0 2.759 2.759 0 01-2.762 2.75.703.703 0 000 1.406zm0 0"
          />
        </g>
      </g>
    </svg>
  )
}

export default CleaningIcon
