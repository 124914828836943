import * as React from "react"

function TemperatureIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        data-name="Mask Group 5806"
        transform="translate(-694 -183)"
        clipPath="url(#prefix__clip-path)"
      >
        <path
          data-name="Path 5951"
          d="M696.03 191.462l-1.98 10.238a2.406 2.406 0 002.35 2.9h3.015a1.776 1.776 0 001.757-1.49l.109-.656a1.79 1.79 0 00-1.047-1.93l.711-3.5a.989.989 0 01.821-.788l1.439-6.723h-4.813a2.38 2.38 0 00-2.362 1.949z"
        />
        <path
          data-name="Path 5952"
          d="M707.157 189.774a.7.7 0 00-.547-.261h-1.967l-1.436 6.713h2.267a.7.7 0 00.688-.556l1.138-5.307a.7.7 0 00-.141-.589z"
        />
        <path
          data-name="Path 5953"
          d="M718 191.186a2.1 2.1 0 01-.7.121 12.771 12.771 0 01-7.6-2.079q-.152-.109-.3-.223a9.139 9.139 0 00-.548 3.124v1.827l-1.583 4.4a.7.7 0 00.662.941h1.437v.984a4.458 4.458 0 004.453 4.453h2.766v1.547a.703.703 0 001.406 0v-2.25-.014z"
        />
        <path
          data-name="Path 5954"
          d="M717.333 183.051a26.278 26.278 0 00-8.461.9.7.7 0 00-.5.675c0 2.191 2.771 5.277 8.931 5.277a.7.7 0 00.7-.7v-5.45a.7.7 0 00-.667-.7z"
        />
      </g>
    </svg>
  )
}

export default TemperatureIcon
