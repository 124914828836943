export const amenities = [
  { key: "high_low_litness", name: "Highest to lowest litness" },
  { key: "low_high_litness", name: "Lowest to highest litness" },
  { key: "outdoor_seating", name: "Outdoor seating" },
  { key: "other_games", name: "Other games" },
  { key: "pool_tables", name: "Pool tables" },
  { key: "live_sports", name: "Live sports" },
  { key: "expensive_to_cheaper", name: "$$$ to $" },
  { key: "cheaper_to_expensive", name: "$ to $$$" },
  { key: "darts", name: "Darts" },
];

export const music = [
  { key: "classic_rock", name: "Classic rock" },
  { key: "hip_hop", name: "Hip hop" },
  { key: "indie", name: "Indie" },
  { key: "edm", name: "EDM" },
  { key: "r&b", name: "R&B" },
  { key: "jazz", name: "Jazz" },
  { key: "pop", name: "Pop" },
];

export const capacity = [
  { key: 10, name: "10 ppl" },
  { key: 20, name: "20 ppl" },
  { key: 40, name: "40 ppl" },
  { key: 60, name: "60 ppl" },
  { key: 80, name: "80 ppl" },
  { key: 100, name: "100 ppl" },
  { key: 120, name: "120 ppl" },
  { key: 140, name: "140 ppl" },
  { key: 160, name: "160 ppl" },
  { key: 180, name: "180 ppl" },
  { key: 200, name: "+200 ppl" },
];